import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Element } from "react-scroll";

const MainWrap = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

const CtaButtons = styled.div`
  width: 100%;
  margin: 0.5rem;
  a {
    width: 100%;
    text-align: "center";
    margin-bottom: 1rem;
  }
`;

const PricePanelSecondary = styled.div`
  display: flex;
  flex-direction: row;
`;

const PricePanelWrap = styled.div`
  padding: 0.25rem;
  margin: 0.25rem;
  border-color: #ddd;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
`;

const CourseWrap = styled.div`
  padding: 0.5rem;
`;

const CoursePanel = ({ course }) => {
  return (
    <div key={course.courseName} className="column">
      <div className="card">
        <div className="card-image">
          <a href={course.link} target="_blank" rel="noopener noreferrer">
            {/* <figure className="image is-5by3">
            </figure> */}
            <img src={course.image} alt={course.courseName} />
          </a>
        </div>
        <div className="card-content">
          <div className="content">
            {/* <h4 className="has-text-centered has-text-weight-normal">
              Length:{" "}
              <span className="has-text-weight-medium">{course.length}</span>
            </h4> */}
            <div className="has-text-weight-normal">{course.description}</div>
            <hr />
            <p className="has-text-weight-normal">{course.type}</p>
            <ul>
              {course.items.map((item) => (
                <li key={item} className="is-size-5">
                  {item}
                </li>
              ))}
            </ul>
            <hr />
            {course.price ? (
              <h2 className="is-size-2 has-text-weight-bold has-text-primary has-text-centered">
                {course.price}
              </h2>
            ) : (
              <h2 className="is-size-3 has-text-weight-bold has-text-primary has-text-centered">
                <Link to="/contact">Get in touch!</Link>
              </h2>
            )}
          </div>
        </div>
        <footer className="card-footer">
          {course.cta ? <>{course.cta}</> : null}
        </footer>
      </div>
    </div>
  );
};

const COURSES_LIST = [
  {
    image: "/img/parts-of-a-software-project-thumb-1.jpg",
    link: "https://courses.iteachrecruiters.com/p/parts-of-a-software-project",
    courseName: "Parts of a Software Project",
    description: (
      <div>
        <p>
          A mini-course overview of the process, people, and infrastructure in a
          typical software project.
        </p>
        <p>
          Not sure if you want to get the full course? Try this one out first.
        </p>
      </div>
    ),
    type: "Online Video Course, ~20 minutes",
    length: "About 20 minutes",
    plan: "Single Seat",
    price: "Free",
    items: [],
    cta: (
      <CtaButtons>
        <a
          className="button is-primary"
          href="https://courses.iteachrecruiters.com/p/parts-of-a-software-project"
          target="_blank"
          rel="noopener noreferrer"
        >
          View the curriculum
        </a>
      </CtaButtons>
    ),
  },
  {
    image: "/img/how-to-speak-jargon-thumbs-1.jpg",
    courseName: "How To Speak Software Engineering Jargon For Recruiters",
    length: "About 5.5 hours",
    description: (
      <div>
        <p>
          The flagship Course! Learn the basics of building software and common
          web development technical topics.
        </p>
      </div>
    ),
    type: "Online Video Course, Over 5.5 hours of content.",
    items: [],
    plan: "Single Seat",
    price: "$299.00*",
    link: "https://courses.iteachrecruiters.com/p/web-development-and-software-engineering-basics-for-tech-recruiters",
    cta: (
      <CtaButtons
        onClick={() => {
          try {
            console.log("track conversion");
            window.gtag("event", "conversion", {
              send_to: "AW-863279616/kBGkCOzLwYsYEIC00psD",
              transaction_id: "",
            });
          } catch (e) {
            console.log("error tracking conversion", e);
          }
        }}
      >
        <div>
          *Per user, one time fee. Permanent access.
          <hr />
        </div>
        <a
          className="button is-primary"
          href="https://courses.iteachrecruiters.com/p/web-development-and-software-engineering-basics-for-tech-recruiters"
          target="_blank"
          rel="noopener noreferrer"
        >
          View the curriculum
        </a>
        <a
          href="https://tidycal.com/aaron.b.decker/iteach-recruiters-15-minute-discovery-call"
          className="button is-info"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a call with me for Bulk Pricing
        </a>
      </CtaButtons>
    ),
  },
  {
    image: "/img/custom-training-800.jpg",
    courseName: "Custom Training",
    link: "/contact",
    description:
      "I offer custom training and consulting, I will do live video calls with your team to go over specific technical topics you request.",
    items: [],
    type: "Live Video Call, minimum 1 hour",
    length: "Half day or hourly engagements",
    cta: (
      <CtaButtons>
        <a 
          href="https://tidycal.com/aaron.b.decker/iteach-recruiters-15-minute-discovery-call"
          className="button is-info"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a call with me to explore options
        </a>
      </CtaButtons>
    ),
  },
];

const CourseOfferings = () => (
  <Element name="courseofferings">
    <MainWrap>
      <Title className="has-text-centered has-text-primary is-size-2">
        Courses You Will Love
      </Title>
      <div className="columns">
        {COURSES_LIST.map((course, i) => {
          return <CoursePanel key={i} course={course} />;
        })}
      </div>
    </MainWrap>
  </Element>
);

export default CourseOfferings;
